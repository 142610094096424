import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function Content({
  className,
  gap,
  paddingLeft,
  paddingRight,
  children,
  ...attribs
}) {
  const classes = classNames('content', className);

  return (
    <section
      className={classes}
      data-gap={gap}
      data-padding-left={paddingLeft}
      data-padding-right={paddingRight}
      {...attribs}
    >
      {children}
    </section>
  );
}

Content.defaultProps = {
  gap: null,
  paddingLeft: null,
  paddingRight: null,
  style: null,
};

Content.propTypes = {
  gap: PropTypes.oneOf([null, 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  paddingLeft: PropTypes.oneOf([null, 'none', 'sm', 'md', 'lg', 'xl']),
  paddingRight: PropTypes.oneOf([null, 'none', 'sm', 'md', 'lg', 'xl']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Content;
