import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

function PricingNavLink() {
  const {
    strapiNavigation: { pricing },
  } = useStaticQuery(graphql`
    query {
      strapiNavigation {
        pricing {
          href
          text
        }
      }
    }
  `);

  return (
    <Link
      className="navbar__item"
      activeClassName="navbar__link--active"
      to={pricing.href}
    >
      <span className="navbar__link">{pricing.text}</span>
    </Link>
  );
}

export default PricingNavLink;
