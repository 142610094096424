import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Columns, Content, Rows } from '../../../grid';
import NavDropdownBlock from './dropdown-block';
import DropdownLink from './dropdown-link';

function ResourcesTopNav({ resources, handleLeave }) {
  const leftBlockImage = getImage(resources.leftLinkBlockImage.localFile);
  const rightBlockImage = getImage(resources.rightLinkBlockImage.localFile);

  return (
    <div className="navigation-dropdown__container">
      <Rows gap="sm">
        <Columns count="2">
          <Rows gap="none">
            <GatsbyImage
              image={leftBlockImage}
              className="mb-sm navbar__img"
              alt={resources.leftLinkBlockImage.alternativeText}
            />
            <p className="h4 navigation-dropdown__title mb-xs">
              {resources.leftBlockTitle}
            </p>
            {resources.leftBlock.map(({ primaryText, link }, index) => (
              <DropdownLink
                hideBg={handleLeave}
                to={link}
                key={`solutionsnav-${index}`}
              >
                <p className="navigation-dropdown__text">{primaryText}</p>
              </DropdownLink>
            ))}
          </Rows>
          <Rows gap="none">
            <GatsbyImage
              image={rightBlockImage}
              className="mb-sm navbar__img"
              alt={resources.rightLinkBlockImage.alternativeText}
            />
            <p className="h4 navigation-dropdown__title mb-xs">
              {resources.rightBlockTitle}
            </p>
            {resources.rightLinkBlock.map(({ primaryText, link }, index) => (
              <DropdownLink
                hideBg={handleLeave}
                to={link}
                key={`solutionsnav-${index}`}
              >
                <p className="navigation-dropdown__text">{primaryText}</p>
              </DropdownLink>
            ))}
          </Rows>
        </Columns>
      </Rows>
    </div>
  );
}

function ResourcesBotNav({ resources, handleLeave }) {
  return (
    <div className="navigation-dropdown__container navigation-dropdown__container--footer">
      <Rows gap="xs">
        <Columns count="1">
          <Rows gap="xs">
            <Content paddingLeft="none" gap="xs">
              <p className="h4 navigation-dropdown__title">
                {resources.footerTitle}
              </p>
              <p className="navigation-dropdown__text navigation-dropdown__subtitle">
                {resources.footerText}
              </p>
            </Content>
          </Rows>
        </Columns>
        <Rows gap="none">
          <Columns className="grid__columns--gapless" count="2">
            {resources.footerLinks.map(({ primaryText, link }, index) => (
              <DropdownLink
                hideBg={handleLeave}
                to={link}
                key={`solutionsnav-${index}`}
              >
                <p className="navigation-dropdown__text">{primaryText}</p>
              </DropdownLink>
            ))}
          </Columns>
        </Rows>
      </Rows>
    </div>
  );
}

function ResourcesNavLink({ showBg, hideBg }) {
  const {
    strapiNavigation: { resources },
  } = useStaticQuery(graphql`
    query ResourcesNavQuery {
      strapiNavigation {
        resources {
          link {
            href
            text
          }
          leftBlockTitle
          leftBlock {
            primaryText
            link
          }
          leftLinkBlockImage {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 64, layout: FIXED)
              }
            }
          }
          rightBlockTitle
          rightLinkBlockImage {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 64, layout: FIXED)
              }
            }
          }
          rightLinkBlock {
            link
            primaryText
          }
          footerTitle
          footerText
          footerLinks {
            link
            primaryText
          }
        }
      }
    }
  `);

  return (
    <NavDropdownBlock
      linkText={resources.link.text}
      linkClass="resources"
      linkUrl={resources.link.href}
      showBg={showBg}
      hideBg={hideBg}
    >
      <ResourcesTopNav resources={resources} />
      <ResourcesBotNav resources={resources} />
    </NavDropdownBlock>
  );
}

export default ResourcesNavLink;
