import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';

function NavDropdownBlock({
  linkText,
  linkUrl,
  linkClass,
  showBg,
  hideBg,
  children,
}) {
  const elementRef = useRef(null);
  const [active, setActive] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleEnter = () => {
    setActive(true);

    setTimeout(() => {
      setVisible(true);
    }, 100);
  };

  useEffect(() => {
    if (active) {
      showBg(elementRef.current.getBoundingClientRect());
    }
  }, [active, showBg]);

  const handleLeave = () => {
    setActive(false);
    hideBg();
    setTimeout(() => {
      setVisible(false);
    }, 110);
  };

  const classes = classNames(
    'navbar__item navbar__item--dropdown',
    active && 'navbar__item--active',
    visible && 'navbar__item--visible',
  );

  return (
    <li
      role="presentation"
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      className={classes}
    >
      <Link
        activeClassName="navbar__link--active"
        className="navbar__link navbar__link--dropdown"
        to={linkUrl}
      >
        {linkText}
      </Link>
      <div
        ref={elementRef}
        className={`navigation-dropdown navigation-dropdown--${linkClass}`}
      >
        {children.map((child, index) => React.cloneElement(child, { handleLeave, key: index }))}
      </div>
    </li>
  );
}

NavDropdownBlock.propTypes = {
  showBg: PropTypes.func,
  hideBg: PropTypes.func,
};

export default NavDropdownBlock;
