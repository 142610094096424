import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import Link from '../content/link';

function Button({
  onClick,
  href,
  formButton,
  icon,
  styletype,
  size,
  color,
  className,
  innerRef,
  'aria-label': ariaLabel,
  children,
  openTab,
  ...attributes
}) {
  const classes = classNames(
    'button',
    `button--${size}`,
    `button--${styletype}`,
    `button--${color}`,
    className,
  );

  const handleClick = (e) => {
    if (attributes.disabled) {
      e.preventDefault();
      return;
    }

    if (onClick) {
      return onClick(e);
    }
  };

  if (formButton) {
    attributes.type = 'submit';
  }

  const Tag = href ? Link : 'button';
  const defaultAriaLabel = attributes.close ? 'Close' : null;

  return (
    <Tag
      {...attributes}
      button="true"
      to={href}
      className={classes}
      ref={innerRef}
      onClick={handleClick}
      aria-label={ariaLabel || defaultAriaLabel}
      blank={openTab}
    >
      {children}
      {' '}
      {icon && <FontAwesomeIcon className="button__icon" icon={icon} />}
    </Tag>
  );
}

const requiredPropsCheck = (props, componentName) => {
  if (!props.formButton && !props.onClick && !props.href) {
    return new Error(
      `One of 'onClick' or 'href' is required by '${componentName}' component.`,
    );
  }
};

Button.defaultProps = {
  icon: faChevronRight,
  styletype: 'solid',
  color: 'primary',
  size: 'md',
  className: null,
  formButton: false,
  openTab: false,
};

Button.propTypes = {
  onClick: requiredPropsCheck,
  href: requiredPropsCheck,
  formButton: PropTypes.bool,
  active: PropTypes.bool,
  'aria-label': PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  styletype: PropTypes.oneOf(['solid', 'blank']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Button;
