import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Button from "../../interactive/button"

function NavButtons() {
  const {
    strapiNavigation: { cta },
  } = useStaticQuery(graphql`
    query {
      strapiNavigation {
        cta {
          color
          link
          text
          type
        }
      }
    }
  `)

  return (
    <nav className="navbar__buttons">
      <Button
        href="https://dashboard.lunio.ai/login"
        className="navbar__buttons--login"
        styletype="solid"
      >
        Login
      </Button>
      <Button href={cta.link} styletype="solid">
        {cta.text}
      </Button>
    </nav>
  )
}

export default NavButtons
