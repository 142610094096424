import React from "react"
import { Link as InternalLink } from "gatsby"
import classNames from "classnames"
import PropTypes from "prop-types"

function Link({ to, blank, nofollow, className, children, ...attributes }) {
  const regex = /^((https:\/\/)?(www\.)?lunio\.ai.*)|(^\/.*$)/gm
  const internal = regex.test(to)
  const classes = classNames(!attributes.button && "link", className)
  const Tag = internal ? InternalLink : "a"

  if (internal) {
    attributes.to = to
  } else {
    attributes.href = to
  }

  if (blank) {
    attributes.target = "_blank"
  }

  if (nofollow) {
    attributes.rel = "noreferrer nofollow"
  }

  return (
    <Tag className={classes} {...attributes}>
      {children}
    </Tag>
  )
}

Link.defaultProps = {
  to: "/",
  blank: false,
  nofollow: false,
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  blank: PropTypes.bool,
  nofollow: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Link
