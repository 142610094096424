import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { faMountains } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Link from "../../content/link"
import { Columns } from "../../grid"
import FooterLanguageSelect from "./language-select"
import theme from "../../../theme"

function FooterPostBar() {
  const {
    strapiFooter: { ContactUsLink, PrivacyLink, TermsLink, DataProtection },
  } = useStaticQuery(graphql`
    query FooterCopyrightQuery {
      strapiFooter {
        ContactUsLink {
          text
          href
        }
        PrivacyLink {
          text
          href
        }
        TermsLink {
          href
          text
        }
        DataProtection {
          href
          text
        }
      }
    }
  `)

  return (
    <Columns className="footer__postbar" count="1,2" alignment="center">
      <div className="footer__postbar-left">
        <Link className="footer__link tab-mr-lg" to={ContactUsLink.href}>
          {ContactUsLink.text}
        </Link>
        <FooterLanguageSelect />
      </div>
      <div className="footer__postbar-right">
        <div className="footer__copyright">
          <p className="footer__link footer__link--fake">
            © Lunio {new Date().getFullYear()}
          </p>
          <FontAwesomeIcon
            icon={faMountains}
            className="footer__copyright__seperator"
          />
          <a className="footer__link" rel="noreferrer noopener" href="https://app.privasee.io/privacy-portal/6672ccba557ecb0014a73dac" target="_blank">
            {PrivacyLink.text}
          </a>
          <FontAwesomeIcon
            icon={faMountains}
            className="footer__copyright__seperator"
          />
          <Link className="footer__link footer__link" to={DataProtection.href}>
            {DataProtection.text}
          </Link>
          <FontAwesomeIcon
            icon={faMountains}
            className="footer__copyright__seperator"
          />
          <Link className="footer__link" to={TermsLink.href}>
            {TermsLink.text}
          </Link>
        </div>

        <div className="footer__socicons">
          <Link
            to="https://facebook.com/luniohq"
            aria-label="facebook link"
            blank
            nofollow
            className="footer__socicon"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </Link>
          <Link
            to="https://www.linkedin.com/company/ppcprotect/"
            aria-label="linkedin link"
            blank
            nofollow
            className="footer__socicon"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </Link>
          <Link
            to="https://twitter.com/luniohq"
            aria-label="twitter link"
            blank
            nofollow
            className="footer__socicon"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </Link>
        </div>
      </div>
    </Columns>
  )
}

export default FooterPostBar
