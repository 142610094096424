import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import ConditionalWrapper from "../../utils/ConditionalWrapper"
import Link from "../content/link"

function Card({
  styleType,
  padding,
  onClick,
  link,
  className,
  children,
  fullHeight,
  ...attributes
}) {
  const classes = classNames(
    "card",
    `card--${styleType}`,
    (link || onClick) && `card--${styleType}--interactable`,
    padding && `card--${padding}`,
    className,
  )

  const clickHandler = {
    onClick,
    role: onClick ? "button" : undefined,
    tabIndex: onClick ? "0" : undefined,
  }

  return (
    <ConditionalWrapper
      condition={link}
      wrapper={children => (
        <Link className={fullHeight ? "height--full" : null} to={link}>
          {children}
        </Link>
      )}
    >
      <div className={classes} {...clickHandler} {...attributes}>
        {children}
      </div>
    </ConditionalWrapper>
  )
}

Card.defaultProps = {
  styleType: "hollow",
  padding: "sm",
  fullHeight: false,
}

Card.propTypes = {
  styleType: PropTypes.oneOf(["solid", "hollow", "white"]).isRequired,
  onClick: PropTypes.func,
  link: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
  fullHeight: PropTypes.bool.isRequired,
}

export default Card
