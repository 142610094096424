module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"colors":{"core":{"core":"#7b2cbf","coreLight":"#a673d6","coreDark":"#30154f"},"vibrant":{"orange":"#ff6d00","orangeInt":"#ff9d00","yellow":"#ffc31f","yellowInt":"#f0cc10","red":"#ec4534","green":"#55a630"},"lights":{"white":"#ffffff","notQuiteWhite":"#f2f2f2","subtleWhite":"#faf9fa","fragileWhite":"#f0f0f0"},"darks":{"black":"#100b15","notQuiteBlack":"#202020","blackInt":"#100b15af","darkGrey":"#423e45","midGrey":"#a9a4ae","lightGrey":"#dfe6e9","lightGreyInt":"#ebf0f2","shadowLight":"#f4f2f6","shadowDark":"#e2e2e3"},"yellows":{"lighterYellow":"#F5D250"},"pinks":{"lighterPink":"#E6CCFF"},"accents":{"pebble":"#485460","ragnarok":"#e84118","terra":"#05c46b","nebula":"#575fcf","coral":"#f368e0","arcade":"#ffa801","midgard":"#e4c6ff","qwerty":"#00d8d6"}},"fonts":{"axiforma":"\"Axiforma\", Tahoma, Arial, sans-serif","poppins":"\"Poppins\", Arial, sans-serif"},"spacing":{"xxxs":"0.25rem","xxs":"0.5rem","xs":"1rem","sm":"1.5rem","md":"2rem","lg":"3rem","xl":"4rem","xxl":"6rem"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T6GQD6H","includeInDevelopment":false,"routeChangeEventName":"PAGE_ROUTE_CHANGE","enableWebVitalsTracking":true,"defaultDataLayer":null,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-96},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"lunio","short_name":"Lunio","start_url":"/","background_color":"#ffffff","theme_color":"#7b2cbf","display":"standalone","icon":"static/android-chrome-192x192.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c166e0309a1730a1cbbbfa89442cc32f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://midgardcdn.com/niflheim/graphql","develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":1000000000,"hardCacheData":false},"type":{"Post":{"limit":5000},"Comment":{"exclude":true},"Menu":{"exclude":true},"PostFormat":{"exclude":true},"ContentType":{"exclude":true},"Taxonomy":{"exclude":true},"UserRole":{"exclude":true},"Tag":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
