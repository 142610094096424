import React from "react"
import Head from "./head"
import NavBar from "./navbar"
import SiteFooter from "./footer"

function Layout({ children, lightNav, simple, hideFooter }) {
  return (
    <>
      <Head />
      {!simple && <NavBar lightNav={!!lightNav} />}
      {children}
      {!simple && !hideFooter && <SiteFooter />}
    </>
  )
}

export default Layout
