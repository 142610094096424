import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Columns } from "../../grid"
import Link from "../../content/link"

function FooterPreBar() {
  return (
    <Columns count="2" className="mb-lg" alignment="center">
      <div className="footer__prebar-left">
        <StaticImage
          className="footer__prebar-logo"
          alt="lunio logo"
          src="../../../images/logo/Lunio_Colour.svg"
        />
      </div>
      <div className="footer__prebar-right">
        <StaticImage
          className="footer__prebar-right__item"
          src="../../../images/assets/badges/tag-badge.png"
          alt="Tag Badge"
          height={80}
          quality="100"
        />
        <StaticImage
          className="footer__prebar-right__item"
          src="../../../images/assets/badges/iab-badge.png"
          alt="iab badge member"
          height={80}
          width={80}
          quality="100"
        />
        <Link
          to="https://www.g2.com/products/ppc-protect/reviews"
          aria-label="G2 Reviews Badge Link"
          nofollow
          blank
        >
          <StaticImage
            className="footer__prebar-right__item"
            src="../../../images/assets/badges/g2-badge.png"
            alt="g2 badge"
            height={80}
            width={160}
            quality="100"
          />
        </Link>
      </div>
    </Columns>
  )
}

export default FooterPreBar
