import React from "react"
import { navigate } from "gatsby-link"

function DropdownLink({ to, hideBg, newTab, children }) {
  const regex = /^((https:\/\/)?(www\.)?lunio\.com.*)|(^\/.*$)/gm
  const internal = regex.test(to) && to !== "https://lunio.ai/marketplace/"

  if (internal) {
    const handleClick = e => {
      e.preventDefault()
      navigate(to)
      hideBg()
    }
    return (
      // eslint-disable-next-line
      <a
        role="button"
        href={to}
        tabIndex={0}
        className="link"
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        {children}
      </a>
    )
  }
  if (newTab) {
    return (
      <a target="_blank" rel="noreferrer" href={to}>
        {children}
      </a>
    )
  }
  return <a href={to}>{children}</a>
}

export default DropdownLink
