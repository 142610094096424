import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Rows({
  gap, className, style, children,
}) {
  const classes = classNames('grid__rows', className);

  return (
    <div className={classes} style={style} data-row-gap={gap}>
      {children}
    </div>
  );
}

Rows.defaultProps = {
  gap: 'md',
};

Rows.propTypes = {
  gap: PropTypes.oneOf([
    'none',
    'xxxs',
    'xxs',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    'xxl',
  ]).isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Rows;
