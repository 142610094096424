module.exports = {
  colors: {
    core: {
      core: "#7b2cbf",
      coreLight: "#a673d6",
      coreDark: "#30154f",
    },
    vibrant: {
      orange: "#ff6d00",
      orangeInt: "#ff9d00",
      yellow: "#ffc31f",
      yellowInt: "#f0cc10",
      red: "#ec4534",
      green: "#55a630",
    },
    lights: {
      white: "#ffffff",
      notQuiteWhite: "#f2f2f2",
      subtleWhite: "#faf9fa",
      fragileWhite: "#f0f0f0",
    },
    darks: {
      black: "#100b15",
      notQuiteBlack: "#202020",
      blackInt: "#100b15af",
      darkGrey: "#423e45",
      midGrey: "#a9a4ae",
      lightGrey: "#dfe6e9",
      lightGreyInt: "#ebf0f2",
      shadowLight: "#f4f2f6",
      shadowDark: "#e2e2e3",
    },
    yellows: {
      lighterYellow: "#F5D250",
    },
    pinks: {
      lighterPink: "#E6CCFF",
    },
    accents: {
      pebble: "#485460",
      ragnarok: "#e84118",
      terra: "#05c46b",
      nebula: "#575fcf",
      coral: "#f368e0",
      arcade: "#ffa801",
      midgard: "#e4c6ff",
      qwerty: "#00d8d6",
    },
  },
  fonts: {
    axiforma: '"Axiforma", Tahoma, Arial, sans-serif',
    poppins: '"Poppins", Arial, sans-serif',
  },
  spacing: {
    xxxs: "0.25rem", // 4px
    xxs: "0.5rem", // 8px
    xs: "1rem", // 16px
    sm: "1.5rem", // 24px
    md: "2rem", // 32px
    lg: "3rem", // 48px
    xl: "4rem", // 64px
    xxl: "6rem", // 96px
  },
}
