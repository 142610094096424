import React, { useState } from "react"
import { faBars, faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { navigate, useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"
import Card from "../../containers/card"
import Button from "../../interactive/button"

function NavMobile() {
  const {
    strapiNavigation: {
      cta,
      product,
      customers,
      solutions,
      resources,
      pricing,
    },
  } = useStaticQuery(graphql`
    query {
      strapiNavigation {
        cta {
          color
          link
          text
          type
        }
        product {
          href
          text
        }
        customers {
          href
          text
        }
        solutions {
          link {
            href
            text
          }
          leftLinkBlocks {
            primaryText
            link
          }
          rightLinkBlockLinks {
            link
            primaryText
          }
        }
        resources {
          link {
            href
            text
          }
          leftBlock {
            link
            primaryText
          }
          rightLinkBlock {
            link
            primaryText
          }
        }
        pricing {
          href
          text
        }
      }
    }
  `)

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleNavClick = url => {
    navigate(url)
    handleClose()
  }

  const mainClasses = classNames(
    "navbar__mobile__menu",
    open && "navbar__mobile__menu--open",
  )

  return (
    <nav className="navbar__mobile">
      <div
        className="navbar__mobile__button"
        aria-label="Hamburger Menu Mobile Button"
        tabIndex="0"
        role="button"
        onClick={handleOpen}
        onKeyDown={handleOpen}
      >
        <FontAwesomeIcon icon={faBars} />
      </div>
      <div className={mainClasses}>
        <Card padding="none" className="navbar__mobile__main" styleType="solid">
          <div className="navbar__mobile__main-bar">
            <div>
              <Button
                size="sm"
                className="mr-xs mb-xxs tab-mb-none"
                href="https://dashboard.lunio.ai/login"
              >
                Login
              </Button>
              <Button size="sm" href={cta.link}>
                {cta.text}
              </Button>
            </div>
            <div
              role="button"
              styletype="blank"
              tabIndex="0"
              onKeyDown={handleClose}
              onClick={handleClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
          <div className="navbar__mobile__section">
            <Button
              size="sm"
              styletype="blank"
              onClick={() => handleNavClick(product.href)}
            >
              {product.text}
            </Button>
          </div>
          <div className="navbar__mobile__section">
            <Button
              size="sm"
              styletype="blank"
              onClick={() => handleNavClick(customers.href)}
            >
              {customers.text}
            </Button>
          </div>
          <div className="navbar__mobile__section">
            <Button
              size="sm"
              styletype="blank"
              onClick={() => handleNavClick(solutions.link.href)}
            >
              {solutions.link.text}
            </Button>
            <div className="navbar__mobile__subsection navbar__mobile__subsection--solutions">
              {solutions.leftLinkBlocks.map(({ primaryText, link }, index) => (
                <Button
                  key={`solutions-mob-${index}`}
                  size="sm"
                  styletype="blank"
                  icon={false}
                  onClick={() => handleNavClick(link)}
                >
                  {primaryText}
                </Button>
              ))}
              {solutions.rightLinkBlockLinks.map(
                ({ primaryText, link }, index) => (
                  <Button
                    key={`solutions-mob-${index}`}
                    size="sm"
                    styletype="blank"
                    icon={false}
                    onClick={() => handleNavClick(link)}
                  >
                    {primaryText}
                  </Button>
                ),
              )}
            </div>
          </div>
          <div className="navbar__mobile__section">
            <Button
              size="sm"
              styletype="blank"
              onClick={() => handleNavClick(resources.link.href)}
            >
              {resources.link.text}
            </Button>
            <div className="navbar__mobile__subsection">
              {resources.leftBlock.map(({ primaryText, link }, index) => (
                <Button
                  key={`resources-mob-${index}`}
                  size="sm"
                  styletype="blank"
                  icon={false}
                  onClick={() => handleNavClick(link)}
                >
                  {primaryText}
                </Button>
              ))}
              {resources.rightLinkBlock.map(({ primaryText, link }, index) => (
                <Button
                  key={`resources-mob-${index}`}
                  size="sm"
                  styletype="blank"
                  icon={false}
                  onClick={() => handleNavClick(link)}
                >
                  {primaryText}
                </Button>
              ))}
            </div>
          </div>
          <div className="navbar__mobile__section">
            <Button
              size="sm"
              styletype="blank"
              onClick={() => handleNavClick(pricing.href)}
            >
              {pricing.text}
            </Button>
          </div>
        </Card>
      </div>
    </nav>
  )
}

export default NavMobile
