import React from "react"
import Helmet from "react-helmet"

function Head() {
  return (
    <Helmet key="app-head" defaultTitle="Ad Fraud Protection by Lunio">
      <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
      <link rel="dns-prefetch" href="//fonts.googleapis.com" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#603cba" />
      <meta name="theme-color" content="#ffffff" />
      <script
        charset="utf-8"
        type="text/javascript"
        src="//js.hsforms.net/forms/shell.js"
      />
    </Helmet>
  )
}

export default Head
