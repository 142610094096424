import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SectionContent from '../../containers/section-content';
import { Rows } from '../../grid';
import FooterBlogCard from './blog-card';
import LinkBlock from './link-block';
import FooterPostBar from './post-bar';
import FooterPreBar from './pre-bar';

function SiteFooter() {
  const {
    strapiFooter: { links },
    wpPage,
  } = useStaticQuery(graphql`
    query {
      strapiFooter {
        links {
          columnTitle
          link {
            text
            href
          }
        }
      }
      wpPage(slug: { eq: "blog" }) {
        blogPage {
          footerFeatured {
            ... on WpPost {
              realSlug
              title
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(aspectRatio: 1)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <footer className="footer">
      <SectionContent paddingTop="xs" paddingBot="xxs">
        <Rows>
          <FooterPreBar />
          <div className="footer__main">
            <div className="footer__linkblocks">
              {links.map(({ columnTitle, link }) => (
                <LinkBlock key={columnTitle} title={columnTitle} links={link} />
              ))}
            </div>
            <Rows className="footer__postblock" gap="none">
              {wpPage.blogPage.footerFeatured.map((item, index) => (
                <FooterBlogCard post={item} key={`footer-post-${index}`} />
              ))}
            </Rows>
          </div>
          <div className="footer__seperator" />
          <FooterPostBar />
        </Rows>
      </SectionContent>
    </footer>
  );
}

export default SiteFooter;
