import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

function CustomersNavLink() {
  const {
    strapiNavigation: { customers },
  } = useStaticQuery(graphql`
    query {
      strapiNavigation {
        customers {
          href
          text
        }
      }
    }
  `);
  return (
    <Link
      className="navbar__item"
      activeClassName="navbar__link--active"
      to={customers.href}
    >
      <span className="navbar__link">{customers.text}</span>
    </Link>
  );
}

export default CustomersNavLink;
