import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function CtaGroup({
  className, alignment, gapSize, style, children,
}) {
  const classes = classNames(
    'cta-group',
    gapSize !== 'md' && `cta-group--${gapSize}-gap`,
    alignment && `cta-group--${alignment}`,
    className,
  );

  return (
    <div style={style} className={classes}>
      {children}
    </div>
  );
}

CtaGroup.defaultProps = {
  className: null,
  gapSize: 'md',
  style: null,
  alignment: null,
};

CtaGroup.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  gapSize: PropTypes.oneOf(['sm', 'md', 'lg']).isRequired,
  alignment: PropTypes.oneOf(['center', 'end']),
  children: PropTypes.node.isRequired,
};

export default CtaGroup;
