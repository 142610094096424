import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Columns({
  count,
  rowgap,
  colgap,
  className,
  alignment,
  children,
  justify,
  ...attributes
}) {
  const classes = classNames(
    'grid__columns',
    rowgap && 'grid__columns--rowgap',
    colgap && 'grid__columns--colgap',
    className,
  );

  return (
    <div
      className={classes}
      data-columns={count}
      data-alignment={alignment}
      data-justify={justify}
      {...attributes}
    >
      {children}
    </div>
  );
}

Columns.defaultProps = {
  alignment: null,
  className: null,
  rowgap: false,
};

Columns.propTypes = {
  count: PropTypes.oneOf([
    '1',
    '2',
    '2,1',
    '2,1,1',
    '1,2',
    '1,3',
    '3',
    '3,1',
    '4',
    '4,1',
    'auto',
  ]).isRequired,
  rowgap: PropTypes.bool,
  className: PropTypes.string,
  justify: PropTypes.oneOf(['bottom', 'center']),
  alignment: PropTypes.oneOf(['start', 'center', 'end']),
  children: PropTypes.node.isRequired,
};

export default Columns;
