import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Link from "../../content/link"

function FooterBlogCard({ post }) {
  const imgFile = getImage(post.featuredImage.node.localFile)

  return (
    <div className="footer__blog-card">
      <Link className="footer__blog-card__inner" to={post.realSlug}>
        <GatsbyImage
          className="footer__blog-card__image"
          image={imgFile}
          alt={post.featuredImage.node.altText}
        />
        <p className="h4 text--lg">{post.title}</p>
      </Link>
    </div>
  )
}

export default FooterBlogCard
