import React from "react"
import Layout from "./src/components/layout"

import "./src/styles/main.scss"
import "react-phone-input-2/lib/plain.css"

// NEEDED TO FIX CSS FOR FONT TAWESOME
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"

config.autoAddCss = false /* eslint-disable import/first */

export const wrapPageElement = ({ element, props }) => (
  <Layout
    lightNav={props.pageContext.lightNav}
    simple={props.pageContext.simple}
    {...props}
    hideFooter={props.pageContext.hideFooter}
  >
    {element}
  </Layout>
)

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}
