import React from "react"
import PropTypes from "prop-types"
import Link from "../../content/link"
import { Content, Rows } from "../../grid"

function LinkBlock({ title, links }) {
  return (
    <Content gap="xl">
      <p className="h4 text--lg">{title}</p>
      <Rows gap="xs">
        {links.map(({ text, href }, index) => (
          <Link className="footer__link" to={href} key={`${title}-${index}`}>
            {text}
          </Link>
        ))}
      </Rows>
    </Content>
  )
}

LinkBlock.defaultProps = {
  title: "I'm Propless",
  links: [],
}

LinkBlock.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
}

export default LinkBlock
