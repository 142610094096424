import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function SectionContent({
  width,
  paddingTop,
  paddingBot,
  children,
  hero,
  className,
  ...attributes
}) {
  const classes = classNames(
    'section__container',
    {
      [`section__container--${width}`]: width,
    },
    className,
  );

  const sectionClasses = classNames(
    'section__layout',
    !hero && 'section__layout--responsive',
  );

  return (
    <div className={classes} {...attributes}>
      <div className={sectionClasses} data-pb={paddingBot} data-pt={paddingTop}>
        {children}
      </div>
    </div>
  );
}

SectionContent.defaultProps = {
  width: false,
  paddingBot: null,
  paddingTop: null,
  hero: false,
};

SectionContent.propTypes = {
  width: PropTypes.oneOf([false, 'wide', 'wider', 'slim', 'full']).isRequired,
  paddingBot: PropTypes.oneOf([null, 'none', 'xxs', 'xs', 'sm', 'lg', 'xl']),
  paddingTop: PropTypes.oneOf([null, 'none', 'xxs', 'xs', 'sm', 'lg', 'xl']),
  hero: PropTypes.bool.isRequired,
};

export default SectionContent;
