import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import ProductNavLink from './links/product-link';
import CustomersNavLink from './links/customers-link';
import SolutionsNavLink from './links/solutions-link';
import ResourcesNavLink from './links/resources-link';
import PricingNavLink from './links/pricing-link';

function NavLinks() {
  const [bgActive, setBgActive] = useState(false);
  const [bgStyle, setBgStyle] = useState({
    width: 0,
    height: 0,
    top: '100px',
    left: '50%',
  });

  const bgClasses = classNames(
    'navigation-dropdown__background',
    bgActive && 'navigation-dropdown__background--active',
  );

  const showBg = useCallback(({
    top, left, width, height,
  }) => {
    setBgActive(true);
    setBgStyle({
      width: `${width}px`,
      height: `${height}px`,
      top: `${top}px`,
      left: `${left}px`,
    });
  }, []);

  const hideBg = () => {
    setBgActive(false);
  };

  return (
    <nav className="navbar__main">
      <div className={bgClasses} style={bgStyle} />
      <ul className="navbar__links">
        <ProductNavLink />
        <CustomersNavLink />
        <SolutionsNavLink showBg={showBg} hideBg={hideBg} />
        <ResourcesNavLink showBg={showBg} hideBg={hideBg} />
        <PricingNavLink />
      </ul>
    </nav>
  );
}

export default NavLinks;
