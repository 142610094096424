import React from "react"
import { Link } from "gatsby"

function NavLogo() {
  return (
    <div className="navbar__logo">
      <Link aria-label="To Home" to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_2"
          viewBox="0 0 165 44.07"
        >
          <defs />
          <g id="Layer_1-2">
            <path
              id="Path_7364"
              d="M47.73 44.04c-4.26 0-7.6-1.17-10.04-3.52-.49-.48-.94-1-1.34-1.56-1.53-2.13-2.3-4.87-2.3-8.19V14.1h8.36v16.73c0 1.7.47 3.05 1.41 4.05.94.99 2.25 1.49 3.91 1.49s2.97-.5 3.91-1.49 1.41-2.34 1.41-4.05V14.1h8.36v16.68c0 4.23-1.21 7.49-3.62 9.8s-5.77 3.46-10.06 3.46Z"
              className="cls-1"
            />
            <path
              id="Path_7367"
              d="M83.46 14.1c4.26 0 7.6 1.17 10.04 3.52s3.65 5.59 3.65 9.75v16.67h-8.36V27.31c0-1.7-.47-3.05-1.41-4.05-.94-.99-2.25-1.49-3.91-1.49s-2.97.5-3.91 1.49-1.41 2.34-1.41 4.05v16.73h-8.36V27.37c0-4.23 1.21-7.49 3.62-9.8s5.77-3.46 10.06-3.46Z"
              className="cls-1"
            />
            <path
              id="Rectangle_19554"
              d="M105.5 14.14h6.23c1.15 0 2.09.94 2.09 2.09v27.84h-8.32V14.14Z"
              className="cls-1"
            />
            <circle
              id="Ellipse_346"
              cx="137.14"
              cy="29.04"
              r="14.97"
              className="cls-1"
            />
            <circle id="dot" cx="158.04" cy="6.96" r="6.96" />
            <path
              d="M25.69 37.8c0-1.15-.93-2.09-2.09-2.09H8.32V16.19c0-1.16-.93-2.09-2.09-2.09H0v29.93h25.69V37.8Z"
              className="cls-1"
            />
          </g>
        </svg>
      </Link>
    </div>
  )
}

export default NavLogo
