import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Columns, Content, Rows } from '../../../grid';
import NavDropdownBlock from './dropdown-block';
import Button from '../../../interactive/button';
import CtaGroup from '../../../containers/cta-group';
import DropdownLink from './dropdown-link';

function SolutionsTopNav({ solutions, handleLeave }) {
  const leftBlockImage = getImage(solutions.leftLinkBlockImage.localFile);
  const rightBlockImage = getImage(solutions.rightLinkBlockImage.localFile);

  return (
    <div className="navigation-dropdown__container">
      <Rows gap="sm">
        <Columns count="2">
          <Rows gap="none">
            <GatsbyImage
              image={leftBlockImage}
              className="mb-sm navbar__img"
              alt={solutions.leftLinkBlockImage.alternativeText}
            />
            <p className="h4 navigation-dropdown__title mb-xs">
              {solutions.leftLinkBlockTitle}
            </p>
            {solutions.leftLinkBlocks.map(({ primaryText, link }, index) => (
              <DropdownLink
                hideBg={handleLeave}
                to={link}
                key={`solutionsnav-${index}`}
              >
                <p className="navigation-dropdown__text">{primaryText}</p>
              </DropdownLink>
            ))}
          </Rows>
          <Rows gap="none">
            <GatsbyImage
              image={rightBlockImage}
              className="mb-sm navbar__img"
              alt={solutions.rightLinkBlockImage.alternativeText}
            />
            <p className="h4 navigation-dropdown__title mb-xs">
              {solutions.rightLinkBlockTitle}
            </p>
            {solutions.rightLinkBlockLinks.map(
              ({ primaryText, link, newTab }, index) => (
                <DropdownLink
                  hideBg={handleLeave}
                  to={link}
                  key={`solutionsnav-${index}`}
                  newTab={newTab}
                >
                  <p className="navigation-dropdown__text">{primaryText}</p>
                </DropdownLink>
              ),
            )}
          </Rows>
        </Columns>
      </Rows>
    </div>
  );
}

function SolutionsBottomNav({ solutions, handleLeave }) {
  return (
    <div className="navigation-dropdown__container navigation-dropdown__container--footer">
      <Rows gap="none">
        <Columns alignment="center" count="2,1">
          <Content paddingLeft="none" gap="xs">
            <p className="h4 navigation-dropdown__title">
              {solutions.footerTitle}
            </p>
            <p className="navigation-dropdown__text navigation-dropdown__subtitle">
              {solutions.footerText}
            </p>
          </Content>
          <CtaGroup alignment="center">
            <Button color="dark" href={solutions.footerButton.link}>
              {solutions.footerButton.text}
            </Button>
          </CtaGroup>
        </Columns>
      </Rows>
    </div>
  );
}

function SolutionsNavLink({ showBg, hideBg }) {
  const {
    strapiNavigation: { solutions },
  } = useStaticQuery(graphql`
    query SolutionsNavQuery {
      strapiNavigation {
        solutions {
          link {
            href
            text
          }
          leftLinkBlockTitle
          leftLinkBlocks {
            link
            primaryText
          }
          leftLinkBlockImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 64)
              }
            }
            alternativeText
          }
          rightLinkBlockTitle
          rightLinkBlockLinks {
            link
            primaryText
            newTab
          }
          rightLinkBlockImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 64)
              }
            }
            alternativeText
          }
          footerText
          footerTitle
          footerButton {
            text
            link
          }
        }
      }
    }
  `);

  return (
    <NavDropdownBlock
      linkText={solutions.link.text}
      linkClass="solutions"
      linkUrl={solutions.link.href}
      showBg={showBg}
      hideBg={hideBg}
    >
      <SolutionsTopNav solutions={solutions} />
      <SolutionsBottomNav solutions={solutions} />
    </NavDropdownBlock>
  );
}

export default SolutionsNavLink;
