import React, { useState, useEffect } from "react"
import classNames from "classnames"
import NavButtons from "./nav-buttons"
import NavLinks from "./nav-links"
import NavLogo from "./nav-logo"
import NavMobile from "./nav-mobile"

function NavBar({ lightNav }) {
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      if (typeof window !== "undefined") {
        const scrollCheck = window.scrollY > 32
        if (scrollCheck !== scroll) {
          setScroll(scrollCheck)
        }
      } else {
        setScroll(true)
      }
    }

    document.addEventListener("scroll", onScroll)

    return () => {
      document.removeEventListener("scroll", onScroll)
    }
  }, [scroll, setScroll])

  const classes = classNames(
    "navbar",
    lightNav && "navbar--light",
    scroll && "navbar--sticky",
  )

  return (
    <header className={classes}>
      <div className="navbar__container">
        <div className="navbar__layout">
          <NavLogo />
          <NavLinks />
          <NavButtons />
          <NavMobile />
        </div>
      </div>
    </header>
  )
}

export default NavBar
