import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

function ProductNavLink() {
  const {
    strapiNavigation: { product },
  } = useStaticQuery(graphql`
    query {
      strapiNavigation {
        product {
          href
          text
        }
      }
    }
  `);

  return (
    <Link
      className="navbar__item"
      activeClassName="navbar__link--active"
      to={product.href}
    >
      <span className="navbar__link">{product.text}</span>
    </Link>
  );
}

export default ProductNavLink;
