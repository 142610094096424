import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import Select, { components } from "react-select"

const mockData = [
  { value: "https://lunio.ai/", label: "UK - English", tld: ".ai" },
]

function Control({ children, ...rest }) {
  return (
    <components.Control {...rest}>
      Language:
      {children}
    </components.Control>
  )
}

function FooterLanguageSelect() {
  const isBrowser = typeof window !== "undefined"
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      padding: 0,
      fontFamily: "Axiforma, Tahoma, Arial, sans-serif",
      border: "none",
      borderRadius: 16,
      overflow: "hidden",
      boxShadow: "0px 0px 25px #0000001a",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#a673d6" : "transparent",
      padding: 16,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: state.isSelected ? "#a673d6" : "#dfe6e9",
      },
    }),
    control: (provided, state) => ({
      ...provided,
      color: "var(--link-color)",
      cursor: "pointer",
      width: 260,
      fontFamily: "Axiforma, Tahoma, Arial, sans-serif",
      fontSize: 18,
      border: "none",
      boxShadow: "none",
      backgroundColor: "transparent",
      "&:hover": {
        color: "var(--link-hover)",
      },
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0 0 0 8px",
      minHeight: "auto",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      overflow: "visible",
      paddingTop: 1,
      color: "inherit",
    }),
  }

  const handleChange = e => {
    if (isBrowser) {
      window.location.assign(e.value)
    }
  }

  return (
    <Select
      onChange={handleChange}
      menuPlacement="auto"
      defaultValue={mockData[0]}
      options={mockData}
      styles={customStyles}
      components={{
        Control,
        IndicatorSeparator: () => null,
        DropdownIndicator: () => (
          <FontAwesomeIcon className="mt-xxxs" icon={faChevronDown} />
        ),
      }}
    />
  )
}

export default FooterLanguageSelect
