import React from 'react';
import classNames from 'classnames';

function Footer({ className, children }) {
  const classes = classNames('content__footer', className);

  return <footer className={classes}>{children}</footer>;
}

export default Footer;
